import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CommonService } from 'src/app/services';
import { SharedserviceService } from 'src/app/services';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // showLeftBar:boolean=false;
  // user_id:any;
  // role_id:any;
  // user:any;

  // constructor(private router:Router) { 
  //   this.user = JSON.parse(localStorage.getItem("userAgency_kalawatiTour"));
  //   
//console.log("user ::::::ASD:::::",this.user)
  //   if(this.user != null){ 
  //     this.user_id = this.user.id;
  //     this.role_id = this.user.role_id;
  //   }
  // }
  

  // ngOnInit() {
  // }
  // logout() {
  //   localStorage.removeItem("tokenAgency_kalawatiTour");
  //   localStorage.removeItem("user");
  //   this.router.navigateByUrl('/')
  // }
  userdata:any;
  user:any;
  user_type:any;
  name:any;
  submitted: boolean = false;
  id:any;
  showlogin:boolean=true;
  parseuser:any;
  getData: any;
  formEmail: FormGroup;
  ownerData: any;
  webMsg: any;
  constructor(private router: Router,public commonService:CommonService,public sharedService: SharedserviceService,private fb: FormBuilder,
    private toastr: ToastrManager) {
    this.userdata= JSON.parse(localStorage.getItem("userAgency_kalawatiTour"));
    this.ownerData = JSON.parse(sessionStorage.getItem("ownerData"));

    
  }

  ngOnInit() { 
    this.contactDetail();
    console.log("Owner data ",this.ownerData)
    this.user=JSON.parse(localStorage.getItem("userAgency_kalawatiTour"));
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      this.showlogin=false;
      this.name = this.user.name;
    } 
    this.formEmail = this.fb.group({
      name: ['',Validators.required],
      email: ['',Validators.required],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      mobile_no: ['',Validators.required],
    
      send_to_email:['']
    });
   this.webSetting();
  } 
  get enq() { return this.formEmail.controls; }

  contactDetail() {

    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        console.log("::::::::::::::",this.getData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.getData));
      } else {
        
      }
    });
  }

  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  } 
  ENQ() {
    $('#Enq_us').modal('show');
} 
closeENQ() {
  $('#Enq_us').modal('hide');
  
}  
enquiryForm(){
  this.submitted = true;
  if (this.formEmail.invalid) { 
    return;
  }else{
  var requestData ={
    "name":this.formEmail.value.name,
    "email":this.formEmail.value.email,
    "subject": this.formEmail.value.subject,
    "message": this.formEmail.value.message,
    "company_name": "test",
    "mobile_no": this.formEmail.value.mobile_no.toString(),
   
    "send_to_email":this.getData.email
  }
  this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
    if (res.replyCode == "success") {
      $('#Enq_us').modal('hide');
      this.submitted = false;
      this.toastr.successToastr(res.replyMsg, 'Success');
    } else {
      this.toastr.errorToastr(res.message, 'Error');
    }
  },err=>{
  
    this.toastr.errorToastr(err.error.replyMsg, 'Error');
  });
}

}  
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
  }
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  }

  webSetting() {
    this.sharedService.get('v1/website_setting').subscribe((res) => {
      if (res.replyCode == 'success') {
        var webSetingData = res.data[0];
        var message = {
          client_msg: webSetingData.client_msg,
          agency_msg: webSetingData.agency_msg,
          show_outside: webSetingData.show_outside,
          book_outside: webSetingData.book_outside
        }
        localStorage.setItem("webMessage", JSON.stringify(message));
        console.log("web Setting======1144", webSetingData);
        this.webMsg = JSON.parse(localStorage.getItem("webMessage"));
      } else {
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    })
  }


}
